
import "./Projects.css";
import Card from "../reusable/Card";

function Projects() {
  return (
    <section className="projects section container" id="projects">
      <h2 className="section-title title_center" data-top-title="Mis Trabajos">
        Proyectos Recientes
      </h2>
      <div className="projects_section">
        <div className="projects_content grid">
          <Card />
        </div> 
      </div>
    </section>
  );
}

export default Projects;
