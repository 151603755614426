import { createContext, useContext, useState } from "react";

export const ThemeContext = createContext();

export const ThemeContextProvider = ({children}) => {
    const [contextTheme, setContextTheme] = useState(()=>{
    if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
       return 'dark' 
    }
    return 'light'
    });
    const values = { contextTheme, setContextTheme};

    return (
        <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    const context = useContext(ThemeContext)
    return context;
}