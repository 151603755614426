// import { useState } from "react";
import "../styles/ProButton.css";

function ProButton({ handleProData, proData }) {
  return (
    <div className="profession_button-group">
      <span className={ proData === 1 ? "profession_button active-link" : "profession_button" } onClick={() => handleProData(1)}>
        <i className="ri-award-fill"></i> Experiencia
      </span>

      <span className={ proData === 2 ? "profession_button active-link" : "profession_button"}  onClick={() => handleProData(2)}>
        <i className="ri-graduation-cap-line"></i> Habilidades
      </span>

      <span className={ proData === 3 ? "profession_button active-link" : "profession_button"} onClick={() => handleProData(3)}>
        <i className="ri-graduation-cap-line"></i> Educación
      </span>
    </div>
  );
}

export default ProButton;
