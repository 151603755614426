import { useState } from "react";
import { FaBars, FaTimes } from 'react-icons/fa'
import { BsFillMoonStarsFill, BsFillSunFill } from 'react-icons/bs'

import "./Navbar.css";

function Navbar({handleTheme, contextTheme}) {
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false)

  // Cerrar menu
  const handleClick = () => {
    setIsMobile(!isMobile)
  }

  // Scroll shadow
  const scrollHeader = () => {
    if (window.scrollY >= 70) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", scrollHeader);


  // Dark mode
  // const handleTheme = () => {
  //   setThemeIcon(!themeIcon)
  // }

  // Cerrar menu al maximizar
window.onresize = function(){
  let w = window.outerWidth;
  if (w >=767 ) {
    setIsMobile(false)
  }
}

  return (
    <header className={scroll ? "header scroll-shadow" : "header"} id="header">
      <nav className="nav container">
        <a href="/" className="nav_logo">
          <i className="ri-drag-move-line nav_logo-icon"></i>
          CristianM
        </a>
        <div className={isMobile ? "nav_menu show-menu" : "nav_menu"}>
          <ul className="nav_list">
            <li className="nav_item" onClick={handleClick}>
              <a href="#aboutme" className="nav_link">
                ¿Quién Soy?
              </a>
            </li>
            <li className="nav_item" onClick={handleClick}>
              <a href="#projects" className="nav_link">
                Trabajos
              </a>
            </li>
            <li className="nav_item" onClick={handleClick}>
              <a href="https://blog-cristianm.vercel.app" className="nav_link">
                Blog
              </a>
            </li>
          </ul>
        </div>

        <div className="nav_buttons">
          <div className="nav_theme" onClick={handleTheme}>
            {contextTheme === 'light'
                ? <BsFillMoonStarsFill className="change-theme" />
                : <BsFillSunFill className="change-theme" />
            }
          </div>  
          
          <div className="nav_toggle" onClick={handleClick}>
            {isMobile ? <FaTimes className="mobile-icon" /> : <FaBars className="mobile-icon" /> }
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
