import PythonIcon from "../../assets/img/svg/python.svg";
import JSIcon from "../../assets/img/svg/javascript.svg";
import NodeJSIcon from "../../assets/img/svg/node-js.svg";
import ReactJSIcon from "../../assets/img/svg/react-js.svg";
import MongoDBIcon from "../../assets/img/svg/mongo-db.png";
import MySQLIcon from "../../assets/img/svg/mysql.svg";
import DockerIcon from "../../assets/img/svg/docker.png";
import GitIcon from "../../assets/img/svg/git.svg";

import "../styles/Scroller.css";
const HabilityScroller = () => {
  return (
    <div className="container section">
      <h2 className="section-title title_center" data-top-title="Tecnologías">
        Mis Habilidades
      </h2>
      <div className="wrapper">
        <div className="item item1">
          <img src={PythonIcon} alt="" />
        </div>
        <div className="item item2">
          <img src={JSIcon} alt="" />
        </div>
        <div className="item item3">
          <img src={NodeJSIcon} alt="" />
        </div>
        <div className="item item4">
          <img src={ReactJSIcon} alt="" />
        </div>
        <div className="item item7">
          <img src={MongoDBIcon} alt="" />
        </div>
        <div className="item item8">
          <img src={MySQLIcon} alt="" />
        </div>
        <div className="item item6">
          <img src={DockerIcon} alt="" />
        </div>
        <div className="item item5">
          <img src={GitIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HabilityScroller;
