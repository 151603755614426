import { useState } from "react";
import "../styles/Card.css";
import "boxicons";

// Icon impports
import {
  BiLogoMongod,
  BiLogoTailwindCss,
  BiLogoGithub,
  BiLogoRedux,
  BiLinkExternal,
} from "react-icons/bi";
import { TbWorldWww } from "react-icons/tb";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { SiMysql, SiHandlebarsdotjs } from "react-icons/si";
import { GiPencilBrush } from "react-icons/gi";

const Card = () => {
  const [dataCards, setDataCards] = useState([
    {
      id: 1,
      title: "Clon de Netflix",
      description:
        "Una replica de la famosa aplicación web de películas en streaming.",
      stack: "Frontend | Backend",
      techIconLink: "github",
      link: "https://github.com/cmoncion/personal-projects/tree/main/netflix-clone",
      proLink: "https://netflix-clone-cm.onrender.com",
      techIcon: ["react", "redux", "nodejs"],
    },
    {
      id: 2,
      title: "E-Commerce App eShop Store",
      description: "Tienda Online de Productos.",
      stack: "Frontend | Backend",
      techIconLink: "github",
      link: "",
      proLink: "https://eshopstore.onrender.com/",
      techIcon: ["react", "nodejs", "mongodb"],
    },

    {
      id: 3,
      title: "Clon de TripadVisor",
      description:
        "Página para buscar destinos turísticos y vacacional. Esta replica fue creada utilizando como NextJs,  TypeScript y Redux.",
      techIconLink: "github",
      stack: "Frontend",
      link: "",
      proLink: "https://tpvr-clone.vercel.app/",
      techIcon: ["react", "tailwind-css", "typescript", "redux"],
    },
    {
      id: 4,
      title: "Todo App React RTK",
      description:
        "Una pequeña app de tareas para practicar el funcionamiento de redux.",
      stack: "Frontend",
      techIconLink: "github",
      link: "https://github.com/cmoncion/personal-projects/tree/main/todo-app-react-redux",
      proLink: "",
      techIcon: ["react", "redux", "tailwind-css"],
    },
  ]);

  return (
    <>
      {dataCards.map((card, index) => (
        <div className="card" key={card.id}>
          <div className="card-links">
            <a href={card.link} target="_blank">
              <BiLogoGithub />
            </a>
            <a href={card.proLink} target="_blank">
              <BiLinkExternal />
            </a>
          </div>
          <div className="card-body">
            <h3 className="card-title">{card.title}</h3>

            <p className="card-description">{card.description}</p>

            <footer className="card-footer">
              <span className="card-stack">{card.stack}</span>
              <div className="tech">
                {card.techIcon.map((icon, i) => (
                  <box-icon
                    type="logo"
                    name={icon}
                    key={i}
                    className="icon"
                    color="#F22D44"
                  ></box-icon>
                ))}
              </div>
            </footer>
          </div>
        </div>
      ))}
    </>
  );
};

export default Card;
