import "./Footer.css";

function Footer() {
  const year = new Date();
  return (
    <footer>
      <span className="footer_copyright">
        &copy; 2023 - {year.getFullYear()}{" "}
        <a href="/" className="footer_copyright-link">
          Cristianmr
        </a>{" "}
        Todos los derechos reservados
      </span>
    </footer>
  );
}

export default Footer;
