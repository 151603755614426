import { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from "./components/navbar/Navbar";
import Profile from "./components/profile/Profile";
import Projects from "./components/projects/Projects";
import HabilityScroller from "./components/reusable/HabilityScroller";
import RecentPosts from "./components/post/RecentPosts";
import Footer from "./components/footer/Footer";

import ScrollUp from "./components/reusable/ScrollUp";
import { useThemeContext } from "./contexts/ThemeContext";


function App() {
  const { contextTheme, setContextTheme } = useThemeContext();

  useEffect(() => {
    // Dark theme
    if (contextTheme === "dark") {
      window.document.documentElement.classList.remove("light");
      window.document.documentElement.classList.add(contextTheme);
    } else {
      window.document.documentElement.classList.remove("dark");
      window.document.documentElement.classList.add(contextTheme);
    }
  }, [contextTheme]);


  // USEREFFECT PARA ANIMACIONES
  useEffect(() => {
    const sr = ScrollReveal({
      origin: "top",
      distance: "60px",
      duration: "1500",
      delay: 400,
    });

    sr.reveal(".profile_figure-center");
    sr.reveal(".profile_name", { delay: 500 });
    sr.reveal(".profile_profession", { delay: 600 });
    sr.reveal(".projects", { delay: 600 });
  }, []);

  const handleTheme = () => {
    setContextTheme((state) => (state === "light" ? "dark" : "light"));
    //setTheme(!theme);

    if (localStorage.getItem("color-theme")) {
      if (localStorage.getItem("color-theme") === "light") {
        document.documentElement.classList.add("dark");
        localStorage.setItem("color-theme", "dark");
      } else {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("color-theme", "light");
      }
    } else {
      if (document.documentElement.classList.contains("dark")) {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("color-theme", "light");
      } else {
        document.documentElement.classList.add("dark");
        localStorage.setItem("color-theme", "dark");
      }
    }
  };

  return (
    <>
      <Navbar handleTheme={handleTheme} contextTheme={contextTheme} />
      <Profile />
      
      <Projects />
      <HabilityScroller />
      {/* <RecentPosts /> */}
      <Footer />
      <ScrollUp />
      <ToastContainer />
    </>
  );
}

export default App;
