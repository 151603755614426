import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeContextProvider>
      <App />
    </ThemeContextProvider>
);
