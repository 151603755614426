import { useState } from 'react'
import '../styles/ScrollUp.css'

function ScrollUp() {
const [scrollUp, setScrollUp] = useState(false)


const handleScrollUp = () => {
    if(window.scrollY >= 200){
        setScrollUp(true)
    }else{
        setScrollUp(false)
    }
}

window.addEventListener('scroll', handleScrollUp)

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" className={scrollUp ? 'scrollup show-button' : 'scrollup'}>
        <i className="ri-arrow-up-s-line scrollup-icon"></i>
    </a>
  )
}

export default ScrollUp