import { toast, Bounce } from 'react-toastify';

import "./Profession.css";
import ProButton from "../reusable/ProButton";
import { Button } from "../reusable/Button";

import {
  BiLogoPython,
  BiLogoNodejs,
  BiLogoGit,
  BiLogoMongodb,
  BiLogoDocker
} from "react-icons/bi";

import { SiMysql } from "react-icons/si";
import { GiPencilBrush } from "react-icons/gi";
import { useState } from "react";

function Projects() {
  const [proData, setProData] = useState(1);
  const handleProData = (id) => {
    setProData(id);
  };

const handleDownloadCV = () => {
  toast('Por favor, ir al Linkedin.!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
    });
}

  return (
    <>
      <ProButton handleProData={handleProData} proData={proData} />
      <div className="profession_section">
        <div
          className={
            proData === 1
              ? "profession_info show_data grid"
              : "profession_info grid"
          }
        >
          <div className="profession_info-group">
            <h3 className="profession_info-number">2.5</h3>
            <p className="profession_info-description">
              Años de <br /> experiencias
            </p>
          </div>
          <div className="profession_info-group">
            <h3 className="profession_info-number">3</h3>
            <p className="profession_info-description">
              Proyectos <br />
              completados
            </p>
          </div>
          <div className="profession_info-group">
            <h3 className="profession_info-number">2</h3>
            <p className="profession_info-description">
              Clientes <br />
              satisfechos
            </p>
          </div>
        </div>

        <div
          className={
            proData === 2
              ? "skills_content show_data grid"
              : "skills_content grid"
          }
        >
          <div className="skills_area">
            <h3 className="skills_title">Frontend</h3>

            <div className="skills_box">
              <div className="skills_group">
                <div className="skills_data">
                  <i className="ri-html5-fill"></i>
                  <div>
                    <h3 className="skills_name">HTML</h3>
                    <span className="skills_level">Intermedio</span>
                  </div>
                </div>

                <div className="skills_data">
                  <i className="ri-css3-fill"></i>
                  <div>
                    <h3 className="skills_name">CSS</h3>
                    <span className="skills_level">Intermedio</span>
                  </div>
                </div>

                <div className="skills_data">
                  <i className="ri-javascript-fill"></i>
                  <div>
                    <h3 className="skills_name">JavaScript</h3>
                    <span className="skills_level">Intermediate</span>
                  </div>
                </div>
              </div>

              <div className="skills_group">
                <div className="skills_data">
                  <i className="ri-reactjs-fill"></i>
                  <div>
                    <h3 className="skills_name">React</h3>
                    <span className="skills_level">Intermedio</span>
                  </div>
                </div>

                <div className="skills_data">
                  <BiLogoGit />
                  <div>
                    <h3 className="skills_name">Git</h3>
                    <span className="skills_level">Intermediate</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="skills_area">
            <h3 className="skills_title">Backend</h3>

            <div className="skills_box">
              <div className="skills_group">
                <div className="skills_data">
                  <BiLogoPython />
                  <div>
                    <h3 className="skills_name">Python</h3>
                    <span className="skills_level">Intermediate</span>
                  </div>
                </div>

                <div className="skills_data">
                  <BiLogoNodejs />
                  <div>
                    <h3 className="skills_name">Node Js</h3>
                    <span className="skills_level">Intermediate</span>
                  </div>
                </div>

                <div className="skills_data">
                  <SiMysql />
                  <div>
                    <h3 className="skills_name">MySQL</h3>
                    <span className="skills_level">Básico</span>
                  </div>
                </div>
              </div>

              <div className="skills_group">
                <div className="skills_data">
                  <BiLogoMongodb />
                  <div>
                    <h3 className="skills_name">Mongo DB</h3>
                    <span className="skills_level">Básico</span>
                  </div>
                </div>

                <div className="skills_data">
                  <BiLogoDocker />
                  <div>
                    <h3 className="skills_name">Docker</h3>
                    <span className="skills_level">Básico</span>
                  </div>
                </div>

                <div className="skills_data">
                  <GiPencilBrush />
                  <div>
                    <h3 className="skills_name">UX/UI</h3>
                    <span className="skills_level">Básico</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="profession_buttons-cv">
          {/* <a href="" className="profession_button-cv">
            <i className="ri-file-download-line"></i> Download CV </a> */}
          <Button onClick={handleDownloadCV}>
            <i className="ri-file-download-line"></i> Download CV
          </Button>
        </div>
      </div>
    </>
  );
}

export default Projects;
