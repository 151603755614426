import "./Profile.css";
import Profession from "../profession/Profession"

import ProfileImg from "../../assets/img/user-profile.png";

function Profile() {
  return (
    <section className="profile container" id="aboutme">
       <div className="profile_container grid">
        <div className="profile_data">
          <div className="profile_figure-center">
            <div className="profile_figure">
              <img src={ProfileImg} alt="Profile figure" />
            </div>
          </div>

          <h2 className="profile_name">Cristian Monción</h2>
          <h3 className="profile_profession">Full Stack developer</h3>

          <ul className="profile_social">
            <a href="https://www.linkedin.com/in/cristian-moncion" className="profile_social-link"  target="_blank">
              <i className="ri-linkedin-box-fill profile_social-icon"></i>
            </a>
            <a href="https://github.com/cmoncion/personal-projects" className="profile_social-link"  target="_blank" >
              <i className="ri-github-fill profile_social-icon"></i>
            </a>
            <a href="#" className="profile_social-link">
              <i className="ri-global-line profile_social-icon"  target="_blank"></i>
            </a> 
          </ul>
        </div>

        <Profession />
       </div>  
    </section>
  );
}

export default Profile;
