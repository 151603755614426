import styled from "styled-components";

export const Button = styled.button`
  background-color: white;
  color: var(--first-color);
  cursor: pointer;
  font-size: 1rem;
  border: 1px solid var(--first-color);
  border-radius: 0.2rem;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  padding: 1rem 1.2rem;

  &:hover {
    background-color: var(--first-color);
    color: white;
  }
`;
